<template>
    <v-container>
      <v-card elevation="4" outlined>
        <v-card-text>
          <v-row>
            <v-card-title>Relatório de Fechamento de Caixa</v-card-title>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="12" sm="3">
              <v-select
                outlined
                dense
                return-object
                flat
                height="10px"
                label="Empresa"
                item-value="id"
                item-text="fantasyName"
                loader-height
                v-model="report.selectEnterprise"
                :items="enterprise"
              ></v-select>
            </v-col>
            <v-btn
              class="mt-3 mr-4"
              width="200"
              color="success"
              type="submit"
              @click="validDate()"
              :disabled="!valid"
            >
              <v-icon class="mr-2">mdi-file-chart</v-icon> Visualizar</v-btn
            >
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-container>
          <Form :component="this" ref="form" />
          <InvalidDateDialog :component="this" v-if="invalidDateDialog" /> 
        </v-container>
      </v-card>
    </v-container>
  </template>
  
  <script>
  import Form from "../../../components/report/close_cashier/Form/index.vue";
  import InvalidDateDialog from "@/views/Reports/close_cashier/invalidDateDialog.vue";
  import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";
  import { GET_ALL_CASHIER } from "../../../store/actions/cashier";
  import { mapGetters } from "vuex";
  
  import jsPDF from "jspdf";
  import "jspdf-autotable";
  import axios from "axios";
  
  export default {
    components: { Form, InvalidDateDialog},
  
    data: () => ({
      dialog: Boolean(),
      valid: false,
      // selectEnterprise: Object(),
      userName: String(),
      enterprise: [],
      cashier: [],
      report: {
        selectEnterprise: Object(),
        selectCashier: null,
        date_initial_close_cashier: null,
        date_finish_close_cashier: null,
      },
      invalidDateDialog: false
    }),
    
  
    methods: {

      validDate() {


        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
        const day = String(currentDate.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;

        if(this.report.date_initial_close_cashier < formattedDate && this.report.date_finish_close_cashier <= formattedDate && this.report.date_initial_close_cashier < this.report.date_finish_close_cashier) {
          this.Generate()
        } else {
          this.invalidDateDialog = true
        }
      },
      async Generate() {
        let payload = {
          enterprise_id: this.report.selectEnterprise.id,
          cashier_id: this.report.selectCashier,
          date_initial_close_cashier: this.report.date_initial_close_cashier,
          date_finish_close_cashier: this.report.date_finish_close_cashier,
        }
        let all_report = Object.assign({}, payload);

        
  
        let response = await axios.post("/sales/getcashclosingreport", {
          ...all_report,
        });
        this.dialog = !this.dialog;
        this.$refs.form.reset();
        this.$refs.form.validate();
        this.$refs.form.resetValidation();
        let arrayenterprise = [];
        let arrayaddress = [];
        let objeto = Object.assign({}, this.report.selectEnterprise);
        let objetoadress = Object.assign({}, this.report.selectEnterprise.address);
        arrayenterprise.push(objeto);
        arrayaddress.push(objetoadress);
        const cashier_operation = Object.keys(response.data.cashier_operation).map(key => response.data.cashier_operation[key]);
        const sales_by_payment_method = Object.keys(response.data.sales_by_payment_method).map(key => response.data.sales_by_payment_method[key]);
        let total_value_form_payment = 0;
        let total_value_movement = 0;
        
        for (const obj of sales_by_payment_method) {
          total_value_form_payment += obj.value_form_payment;
        }
        for (const obj of cashier_operation) {
          total_value_movement += obj.value_movement;
        }
        
        sales_by_payment_method.push( { "form_of_payment_id" : "", "descrition_form_of_payment" : "Valor Total da forma de pagamento", "value_form_payment" : total_value_form_payment } )
        cashier_operation.push( { "id" : "", "date_movement" : "", "type_operation" : "Valor Total da operação", "value_movement" : total_value_movement } )

        function roundToTwoDecimalPlaces(number) {
          return Math.round(number * 100) / 100;
        }

        for (const obj of sales_by_payment_method) {
          obj.value_form_payment = roundToTwoDecimalPlaces(obj.value_form_payment);
        }

        for (const obj of cashier_operation) {
          obj.value_movement = roundToTwoDecimalPlaces(obj.value_movement);
        }

        this.createPDF(cashier_operation, sales_by_payment_method, arrayenterprise, arrayaddress, total_value_form_payment);
      },
  
      createPDF(cashier_operation, sales_by_payment_method, enterprise, address, ) {
        let data = new Date();
  
        // item.forEach((e) => {
        //   if (e.type_people == "LegalPeople") {
        //     e.name = e.fantasyName;
        //     e.cpf = e.cnpj;
        //   } else {
        //     e.dateOfBirth = e.dateOfBirth.split("-").reverse().join("/");
        //   }
        // });
  
        var doc = new jsPDF("l", "pt");
        let salesColumns = [
          { title: "Código da forma de pagamento", dataKey: "form_of_payment_id" },
          { title: "Descrição da forma de pagamento", dataKey: "descrition_form_of_payment" },
          { title: "Valor da forma de pagamento", dataKey: "value_form_payment" },
          
        ];

        let cashierColumns = [
          { title: "Código da operação", dataKey: "id" },
          { title: "Data da operação", dataKey: "date_movement" },
          { title: "Tipo da operação", dataKey: "type_operation" },
          { title: "Valor da operação", dataKey: "value_movement" },
        ];

        doc.setLineWidth(1);
        doc.line(40, 85, 800, 85);
        doc.line(40, 125, 800, 125);
        doc.setFontSize(7);
        doc.text(`Usuário : ${this.userName}`, 700, 40);
        doc.text(`${data.toLocaleString()}`, 700, 50);
  
        doc.setFontSize(15);
        doc.text("RELATÓRIO DE FECHAMENTO DE CAIXA", 270, 70);
  
        doc.setFontSize(7);
        doc.text(`CNPJ : ${enterprise[0].cnpj}`, 40, 100);
        doc.text(`Razão Social : ${enterprise[0].name}`, 240, 100);
        doc.text(`Nome Fantasia : ${enterprise[0].fantasyName}`, 630, 100);
        doc.text(`Rua : ${address[0].street}`, 40, 115);
        doc.text(`Numero : ${address[0].number}`, 250, 115);
        doc.text(`Cidade : ${address[0].city}`, 340, 115);
        doc.text(`Estado : ${address[0].state}`, 550, 115);
        doc.text(`CEP : ${address[0].cep}`, 700, 115);
  
        doc.setFontSize(12);
        const startYsales_by_payment_method = 200;
        doc.text("FORMA DE PAGAMENTO", 330, startYsales_by_payment_method - 10);
        doc.autoTable(salesColumns, sales_by_payment_method, {
          columnStyles: {
            //
          },
          headStyles: {
            fillColor: [230, 230, 230],
            textColor: [54, 54, 54],
          },
          startY: startYsales_by_payment_method,
          theme: "grid",
        });

        
        const startYcashier_operation = 400;
        doc.text("OPERAÇÕES DE CAIXA", 330, startYcashier_operation - 10);

        doc.autoTable(cashierColumns, cashier_operation, {
          columnStyles: {
            //
          },
          headStyles: {
            fillColor: [230, 230, 230],
            textColor: [54, 54, 54],
          },
          startY: startYcashier_operation,
          theme: "grid",
        });
  
        const pages = doc.internal.getNumberOfPages();
        doc.setFontSize(8);
        for (let j = 1; j < pages + 1; j++) {
          doc.setLineWidth(1);
          doc.rect(20, 20, 800, 555);
          doc.setPage(j);
          doc.text(`${j} / ${pages}`, 795, 35);
        }
  
        doc.output("dataurlnewwindow");
      },
  
      async GetAllNoPage() {
        let value = {
          page: null,
          limit: null,
          paginate: false,
        };
        await this.$store.dispatch(GET_ALL_ENTERPRISE, value);
        await this.$store.dispatch(GET_ALL_CASHIER, value);
      },
    },
  
    computed: {
      ...mapGetters(["getEnterprises", "getCashier"]),
      ...mapGetters({ userLogin: "auth/userLogin" }),
    },
  
    created() {
      this.GetAllNoPage();
      this.userName = this.userLogin.user.name;
    },
  
    watch: {
      getEnterprises(val) {
        this.enterprise = Object.keys(val).map((key) => val[key]);
        let aux = Object.assign([], this.enterprise[0]);
        this.report.selectEnterprise = aux;
      },

      getCashier(val) {
        this.cashier = Object.keys(val).map((key) => val[key]);
      }
  
    },
  };
  </script>
  